<template>
    <v-app>
        <v-container fill-height>
            <ckeditor class="fill-height" @ready="onReady" :editor="editor" v-model="konten"/>
        </v-container>
    </v-app>
</template>
<script>
import DocumentEditor from '@ckeditor/ckeditor5-build-decoupled-document'
export default {
    data(){
        return{
            konten:'',
            editor: DocumentEditor,
            editorConfig:{
                fontSize: {
                    options: [
                        9, 11, 12, 13, 'default',
                        17, 19, 21
                    ]
                },
            toolbar: [
                'heading', 'bulletedList', 'numberedList', 'fontSize', 'undo', 'redo'
            ],
            }
        }
    },
    // mounted(){
    //     const editorElement = this.$refs.editorElement;
    //     return DocumentEditor.create(editorElement)
    //     .then(ed=>{
    //         console.log(ed)
    //     })
    //     .catch(err=>console.error(err))
    // },
    methods:{
        onReady(editor){
            editor.ui.getEditableElement().parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.getEditableElement()
            )
        }
    },
    watch:{
        konten(e){
            console.log(e)
        }
    }
}
</script>